<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <svg
    class="spinner"
    width="96px"
    height="96px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
  >
    <circle
      class="path"
      fill="none"
      stroke-width="3"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
</template>

<script setup>
</script>

<style scoped lang="scss">

// Here is where the magic happens

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite,
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% { stroke: #603A96; }
  25% { stroke: #603A96; }
  50% { stroke: #603A96; }
  75% { stroke: #603A96; }
  100% { stroke: #603A96; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: calc($offset / 4);
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}

</style>
