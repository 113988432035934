/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { createRouter, createWebHistory } from 'vue-router';
import Web3 from 'web3';
import NFT from '../views/NFT.vue';
import SmartContract from '../contracts/WorldContract.json';
import store from '../store/index';

const web3 = new Web3(window.ethereum);

const loadMintingContracts = () => {
  const url = window.location.href;
  // store.methods.updateWorldType('nexus');
  // store.methods.updateMintingPrice('0.018');
  // store.methods.updateMintingContract('0x937408048C3725419Ee021aD8E65E5FC4474F80B');
  // store.methods.updateCollectionURL('https://testnets.opensea.io/collection/the-nexus');
  if (url.includes('nexus')) {
    store.methods.updateWorldType('nexus');
    store.methods.updateMintingPrice('0.018');
    store.methods.updateMintingContract('0x56B1e892c9A1089B7440bdeFA9D7dcaB78b7E902');
    store.methods.updateCollectionURL('https://testnets.opensea.io/collection/the-nexus');
  }
  if (url.includes('backyard')) {
    store.methods.updateWorldType('byc');
    store.methods.updateMintingPrice('0.035');
    store.methods.updateMintingContract('0xa8c3c214ae95f06b3b7c3861405fb5a183764b47');
    store.methods.updateCollectionURL('https://testnets.opensea.io/collection/backyard-and-chill');
  }
  if (url.includes('obelisk')) {
    store.methods.updateWorldType('obelisk');
    store.methods.updateMintingPrice('0.035');
    store.methods.updateMintingContract('0xceb3721650F15fF543A5730A55E3DC797743A487');
    store.methods.updateCollectionURL('https://testnets.opensea.io/collection/obelisk');
  }
  if (url.includes('hanami')) {
    store.methods.updateWorldType('hanami');
    store.methods.updateMintingPrice('0.069');
    store.methods.updateMintingContract('0xddfbdd6010f6137ccbfeecb2e5cd9223a79b1354');
    store.methods.updateCollectionURL('https://testnets.opensea.io/collection/hanami');
  }
  if (url.includes('barbeyond')) {
    store.methods.updateWorldType('barbeyond');
    store.methods.updateMintingPrice('0.069');
    store.methods.updateMintingContract('0x053A1fd68EB2C7B4586bA56c4546914C205c69DF');
    store.methods.updateCollectionURL('https://testnets.opensea.io/collection/bar-beyond');
  }
  // Get supply
  const getSupplyObj = new web3.eth.Contract(
    SmartContract.abi,
    store.state.mintingContract,
  );
  getSupplyObj.methods.totalSupply().call((err, result) => {
    store.methods.updateSupply(result);
  });

  getSupplyObj.methods.maxSupply().call((err, result) => {
    store.methods.updateTotalSupply(result);
  });
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/thenexus',
      component: NFT,
      props: { nftType: 'nexus' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/backyard&chill',
      component: NFT,
      props: { nftType: 'backyard' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/obelisk',
      component: NFT,
      props: { nftType: 'obelisk' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/hanami',
      component: NFT,
      props: { nftType: 'hanami' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/barbeyond',
      component: NFT,
      props: { nftType: 'bar beyond' },
      beforeEnter: loadMintingContracts,
    },
    // {
    //   path: '/',
    //   redirect: () => ({ path: '/thenexus' }),
    // },
  ],
});

export default router;
