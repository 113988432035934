<script setup>import { inject } from 'vue';

const store = inject('store');
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div
    id="metaMaskConnectedContainer"
    class="flexContainer"
  >
    <img
      src="../assets/exitsmall.svg"
      alt=""
      class="smallExitIcon"
      @click="store.methods.updateWalletConnected(false)"
    >
    <div
      id="circle"
      class="flexContainer"
    >
      <img
        src="../assets/metafoxlarge.png"
        alt=""
      >
    </div>
    <div class="modalTitle">
      Connected
    </div>
  </div>
</template>

<style scoped>

#metaMaskConnectedContainer {
  height: 260px;
  width: 500px;
  border-radius: 10px;
  background-color: #FFF;
  flex-direction: column;
}

#circle {
  box-sizing: border-box;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  border: 3px solid #603A96;
}

</style>
