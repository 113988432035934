<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
/* eslint-disable vue/require-prop-types */
import { inject, ref } from 'vue';
import Web3 from 'web3';
import SmartContract from '../contracts/WorldContract.json';

const web3 = new Web3(window.ethereum);

// import { ethers } from 'ethers';

const store = inject('store');

// eslint-disable-next-line
const props = defineProps(['type']);

const metamaskSupport = ref(false);

// Get supply
const getSupplyObj = new web3.eth.Contract(
  SmartContract.abi,
  store.state.mintingContract,
);
getSupplyObj.methods.totalSupply().call((err, result) => {
  store.methods.updateSupply(result);
});

getSupplyObj.methods.maxSupply().call((err, result) => {
  store.methods.updateTotalSupply(result);
});

// const walletSignerMsg = ref('Sequin Mint needs to verify ownership of your wallet to link it to your account');
const connectWallet = async () => {
  // console.log(window.ethereum);
  store.methods.updateWalletConnecting(true);
  if (window.ethereum) metamaskSupport.value = true;
  else metamaskSupport.value = false;
  if (metamaskSupport.value) {
    await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (accounts) => {
      store.methods.updateConnectedWallet(accounts[0]);
      // console.log(accounts);

      store.methods.updateWalletConnecting(false);
      store.methods.updateWalletConnected(true);
    });
  } else {
    window.open('https://metamask.io/download/', '_blank');
  }
};

const openMetamaskLink = () => {
  window.open('https://metamask.io/', '_blank');
};

</script>

<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div>
    <!-- THE NEXUS -->
    <div
      class="modal"
    >
      <!-- THE NEXUS -->
      <img
        v-if="props.type === 'nexus'"
        src="../assets/thenexusimage.png"
        alt=""
        class="nftImage"
      >
      <!-- BACKYARD & CHILL -->
      <img
        v-if="props.type === 'backyard'"
        src="../assets/backyardimage.png"
        alt=""
        class="nftImage"
      >
      <!-- OBELISK -->
      <img
        v-if="props.type === 'obelisk'"
        src="../assets/obeliskimage.png"
        alt=""
        class="nftImage"
      >
      <!-- HANAMI -->
      <img
        v-if="props.type === 'hanami'"
        src="../assets/hanamiimage.png"
        alt=""
        class="nftImage"
      >
      <!-- BAR BEYOND -->
      <img
        v-if="props.type === 'bar beyond'"
        src="../assets/barbeyondimage.png"
        alt=""
        class="nftImage"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <img
          src="../assets/exiticon.png"
          alt=""
          style="cursor: pointer; position: absolute; right: 14px; top: 17px;"
          @click="store.methods.updateConnectWalletModalVisible(false)"
        >
        <div class="connectTitle">
          Connect Crypto Wallet
        </div>
        <div class="connectDescription">
          By connecting your wallet, you agree to our Terms of Service and Privacy Policy
        </div>
        <div
          class="metaMaskButton"
          @click="connectWallet"
        >
          <div style="position: absolute; left: 66px;">
            MetaMask
          </div>
          <img
            src="../assets/metafox.png"
            alt=""
            style="position: absolute; left: 7px;;"
          >
        </div>
        <div class="footerText">
          New to Ethereum?
        </div>
        <div
          id="otherFooterText"
          class="footerText"
          @click="openMetamaskLink"
        >
          Learn more about crypto wallets
        </div>
        <div
          v-show="false"
          id="errorText"
          class="footerText"
        >
          Metamask not installed in browser
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.modal {
  height: 566px;
  width: 1200px;
  border-radius: 10px;
  display: flex;
}

#rightPart {
  height: 100%;
  width: 600px;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 0 10px 10px 0;
}

.priceText {
  height: 17px;
  color: #1C1C1C;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.connectTitle {
  height: 28px;
  width: 342px;
  color: #603A96;
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.28px;
  line-height: 29px;
  text-align: center;
}

.connectDescription {
  height: 35px;
  width: 342px;
  color: #1C1C1C;
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 6px;
}

.metaMaskButton {
  height: 60px;
  width: 338px;
  border-radius: 5px;
  background: linear-gradient(88.93deg, #C45800 0%, #FFC120 100%);
  margin-top: 24px;
  color: #FFF;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.32px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  cursor: pointer;
}
.metaMaskButton:hover {
  background: linear-gradient(88.93deg, #C45800 32%, #FBB80C 100%);
}
.metaMaskButton:hover:active {
  background: linear-gradient(88.93deg, #C45800 0%, #FFC120 100%);
}

.footerText {
  height: 17px;
  color: #1C1C1C;
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 31px;
}

#otherFooterText {
  margin-top: 6px;
  font-weight: 600;
  font-family: 'Montserrat-SemiBold';
  color: #603A96;
  cursor: pointer;
}
#otherFooterText:hover {
  text-decoration: underline;
}

#errorText {
  font-weight: 700;
  font-family: 'Montserrat-Bold';
  color: #F00;
  position: absolute;
  bottom: 41px;
}

</style>
