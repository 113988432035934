<script setup>
/* eslint-disable vue/require-prop-types */
import { inject, ref } from 'vue';
import Web3 from 'web3';
import axios from 'axios';
import SmartContract from '../contracts/WorldContract.json';
import Spinner from './Spinner.vue';

const web3 = new Web3(window.ethereum);

// import { ethers } from 'ethers';

const store = inject('store');

// eslint-disable-next-line
const props = defineProps(['type']);

// Get supply
const getSupplyObj = new web3.eth.Contract(
  SmartContract.abi,
  store.state.mintingContract,
);
getSupplyObj.methods.totalSupply().call((err, result) => {
  store.methods.updateSupply(result);
});

getSupplyObj.methods.maxSupply().call((err, result) => {
  store.methods.updateTotalSupply(result);
});

getSupplyObj.methods.presale().call((err, result) => {
  store.methods.updatePresale(result);
});

getSupplyObj.methods.presaleTimestamp().call((err, result) => {
  store.methods.updatePresaleTime(result);
});

getSupplyObj.methods.whitelistClaimed(store.state.connectedWallet).call((err, result) => {
  store.methods.updateClaimed(result);
});

const wrongNetworkError = ref(false);
const mint = async () => {
  // console.log(store.state.worldType, store.state.mintingContract);
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const account = accounts[0];

      const networkId = await window.ethereum.request({
        method: 'net_version',
      });

      // eslint-disable-next-line eqeqeq
      if (networkId != store.state.networkId) {
        // console.log('Wrong network');
        wrongNetworkError.value = true;
      } else {
        const SmartContractObj = new web3.eth.Contract(
          SmartContract.abi,
          store.state.mintingContract,
        );

        const merkleProof = await axios.get(`${store.state.baseURL}/getmerkleproof?worldtype=${store.state.worldType}&wallet=${account}`);

        let mintPrice = web3.utils.toWei((store.state.mintingPrice), 'ether');
        // Solidity timestamp is in seconds
        if ((Date.now() / 1000) <= store.state.presaleTimeExpiration) mintPrice *= 0.5;

        SmartContractObj.methods.mint('1', merkleProof.data)
          .send({
            from: account,
            value: mintPrice,
          })
          .once('error', (err) => {
            console.log(err);
            // Add mint error here////////////////////////

            store.methods.updateShowTransaction(false);
          })
          .then((receipt) => {
            // console.log(receipt);
            console.log('Transaction successfull');
            // Add mint success here////////////////////////
            store.methods.updateShowTransaction(false);
            store.methods.updateShowMintedModal(true);
            store.methods.updateMintModalVisible(false);
            store.methods.updateTransactionHash(receipt.transactionHash);
          });

        store.methods.updateShowTransaction(true);
      }
      // Add listeners start
      window.ethereum.on('accountsChanged', () => {
        // dispatch(updateAccount(accounts[0]));
      });
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    } catch (err) {
      console.log(err);
      // dispatch(connectFailed("Something went wrong."));
    }
  } else {
    console.log('install metamask');
    // dispatch(connectFailed("Install Metamask."));
  }
};
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div>
    <!-- THE NEXUS -->
    <div
      class="modal"
    >
      <!-- THE NEXUS -->
      <img
        v-if="props.type === 'nexus'"
        src="../assets/thenexusimage.png"
        alt=""
        class="nftImage"
      >
      <!-- BACKYARD & CHILL -->
      <img
        v-if="props.type === 'backyard'"
        src="../assets/backyardimage.png"
        alt=""
        class="nftImage"
      >
      <!-- OBELISK -->
      <img
        v-if="props.type === 'obelisk'"
        src="../assets/obeliskimage.png"
        alt=""
        class="nftImage"
      >
      <!-- HANAMI -->
      <img
        v-if="props.type === 'hanami'"
        src="../assets/hanamiimage.png"
        alt=""
        class="nftImage"
      >
      <!-- BAR BEYOND -->
      <img
        v-if="props.type === 'bar beyond'"
        src="../assets/barbeyondimage.png"
        alt=""
        class="nftImage"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <!-- THE NEXUS -->
        <div
          v-if="props.type === 'nexus'"
          class="nftTitle"
        >
          THE NEXUS
        </div>
        <div
          v-if="props.type === 'nexus'"
          class="nftDescription"
        >
          The Nexus is a convergence of peace and quiet. A place
          where the elements meet. A place of serenity. But there is  more here to discover... beneath the surface.<br>
          <br>
          <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span>
        </div>
        <!-- BACKYARD & CHILL -->
        <div
          v-if="props.type === 'backyard'"
          class="nftTitle"
        >
          BACKYARD AND CHILL
        </div>
        <div
          v-if="props.type === 'backyard'"
          class="nftDescription"
        >
          A picturesque, beautiful backyard to call your own.<br>
          This NFT Gallery is the ultimate chill without any of the upkeep. Oh, and don't forget to greet the wildlife.<br>
          <br>
          <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span>
        </div>
        <!-- OBELISK -->
        <div
          v-if="props.type === 'obelisk'"
          class="nftTitle"
        >
          OBELISK
        </div>
        <div
          v-if="props.type === 'obelisk'"
          class="nftDescription"
        >
          An ancient NFT Gallery, discovered after a misguided space tour crash landed here. To a World undiscovered by mankind... until now.
          But be warned, we are not alone at the Obelisk.<br>
          <br>
          <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span>
        </div>
        <!-- HANAMI -->
        <div
          v-if="props.type === 'hanami'"
          class="nftTitle"
        >
          HANAMI
        </div>
        <div
          v-if="props.type === 'hanami'"
          class="nftDescription"
        >
          Miles beneath the earth lives a brilliant source of life in the form of a Cherry Blossom tree.
          Without the sun, this life form still thrives. And while you're down here, so will you and your NFTs.<br>
          <br>
          <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span>
        </div>
        <!-- BAR BEYOND -->
        <div
          v-if="props.type === 'bar beyond'"
          class="nftTitle"
        >
          BAR BEYOND
        </div>
        <div
          v-if="props.type === 'bar beyond'"
          class="nftDescription"
        >
          An exclusive destination where you not only control the VIP NFT list but also the vibe.
          This vibrant space, beaming with color and intricate mechanics exists years in the future…<br>
          <br>
          <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span>
        </div>
        <div
          v-show="!store.state.showTransaction && !store.state.claimed && !wrongNetworkError"
          id="mintButton"
          class="button"
          @click="mint"
        >
          Mint
        </div>
        <div
          v-show="wrongNetworkError"
          id="claimedButton"
          class="button"
        >
          Mint
        </div>
        <div
          v-show="store.state.claimed"
          id="claimedButton"
          class="button"
        >
          World already claimed
        </div>
        <div
          v-show="store.state.showTransaction && !store.state.claimed"
          id="mintButton"
          class="button"
        >
          <Spinner />
          <div style="margin-left: 28px;">
            Waiting for transaction
          </div>
        </div>
        <div
          class="flexContainer"
          style="height: 39px;  margin-top: 39px; flex-direction: column;"
        >
          <div class="priceText">
            Price = {{ store.state.mintingPrice }} eth + gas fees
          </div>
          <div
            class="priceText"
            style="margin-top: 5px;"
          >
            Wallet: {{ store.state.connectedWallet }}
          </div>
        </div>
        <div
          v-show="wrongNetworkError"
          id="errorText"
          class="footerText"
        >
          Error: Wrong network connected.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.modal {
  height: 566px;
  width: 1200px;
  border-radius: 10px;
  display: flex;
}

.nftDescription {
  height: 113px;
  width: 500px;
  color: #000;
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 22px;
}

#rightPart {
  height: 100%;
  width: 600px;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 0 10px 10px 0;
}

#mintButton {
  width: 290px;
  margin-top: 43px;
}

#claimedButton {
  width: 290px;
  margin-top: 43px;
  opacity: 0.4;
  cursor: default;
}
#claimedButton:hover {
  background-color: #7749B7;
  opacity: 0.4;
}

.priceText {
  height: 17px;
  color: #1C1C1C;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.footerText {
  height: 17px;
  color: #1C1C1C;
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 31px;
}

#errorText {
  font-weight: 700;
  font-family: 'Montserrat-Bold';
  color: #F00;
  position: absolute;
  bottom: 41px;
}

</style>
