<script setup>
import { inject } from 'vue';
/* eslint-disable vue/require-prop-types */
import SpinnerLarge from './SpinnerLarge.vue';
/* eslint-disable vue/require-default-prop */

// eslint-disable-next-line
const props = defineProps(['connectingWallet', 'showMintedModal', 'transactionUrl', 'collectionUrl']);
const store = inject('store');

</script>

<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div
    v-show="props.showMintedModal && !props.connectWallet"
    id="smallModalContainer"
    class="flexContainer"
  >
    <img
      v-if="!props.showMintedModal"
      src="../assets/exitsmall.svg"
      alt=""
      class="smallExitIcon"
    >
    <img
      v-show="props.showMintedModal"
      src="../assets/successicon.svg"
      alt=""
    >
    <img
      v-show="false"
      src="../assets/alerticon.svg"
      alt=""
    >
    <div
      v-show="props.showMintedModal"
      class="modalTitle"
    >
      Minted
    </div>
    <div
      v-show="false"
      class="modalTitle"
    >
      Connection Unsuccesful
    </div>
    <div
      v-show="false"
      class="modalTitle"
    >
      Transaction Failed
    </div>
    <div
      v-show="false"
      class="authorizeText"
    >
      Please authorize this website to access your Ethereum account.
    </div>
    <div
      v-show="false"
      class="authorizeText"
    >
      You have insufficient funds. You must have at least 0.00 ETH in your wallet.
    </div>
    <div
      v-show="!props.showMintedModal"
      id="closeButton"
      class="button"
    >
      Close
    </div>
    <div
      v-show="props.showMintedModal"
      id="transactionButton"
      class="whiteButton"
      @click="store.methods.gotoTransaction()"
    >
      View Transaction
    </div>
    <div
      v-show="props.showMintedModal"
      id="collectionButton"
      class="button"
      @click="store.methods.gotoCollection()"
    >
      View Collection
    </div>
  </div>
  <div
    v-if="props.connectingWallet"
    id="smallModalContainer"
    class="flexContainer"
  >
    <img
      src="../assets/exitsmall.svg"
      alt=""
      class="smallExitIcon"
      @click="store.methods.updateWalletConnecting(false)"
    >
    <SpinnerLarge />
    <img
      src="../assets/metafoxlarge.png"
      alt=""
      style="position: absolute; top: 82px;"
    >
    <div
      class="modalTitle"
    >
      Waiting for MetaMask
    </div>
    <div
      class="authorizeText"
    >
      Accept the request to connect your wallet.
    </div>
  </div>
</template>

<style scoped>

#smallModalContainer {
  height: 304px;
  width: 500px;
  border-radius: 10px;
  background-color: #FFF;
  box-shadow: 0 0 10px 0 rgba(0 ,0, 0, 0.5);
  flex-direction: column;
}

.authorizeText {
  height: 35px;
  width: 303px;
  color: #1C1C1C;
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 6px;
}

#closeButton {
  width: 290px;
  margin-top: 27px;
}

#transactionButton {
  width: 290px;
  margin-top: 28px;
  margin-right: 0px;
}

#collectionButton {
  width: 290px;
  margin-top: 16px;
}

</style>
