<script setup>
/* eslint-disable vue/require-prop-types */
import { inject } from 'vue';

// import { ethers } from 'ethers';

const store = inject('store');

// eslint-disable-next-line
const props = defineProps(['type']);
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div>
    <!-- THE NEXUS -->
    <div
      v-show="props.type === 'nexus'"
      class="modal"
    >
      <img
        src="../assets/thenexusimage.png"
        alt=""
        class="nftImage"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <div class="nftTitle">
          THE NEXUS
        </div>
        <div class="nftDescription">
          The Nexus is a convergence of peace and quiet. A place
          where the elements meet. A place of serenity. But there is  more here to discover... beneath the surface.<br>
          <!-- <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }} / {{ store.state.totalSupply }}</span> -->
        </div>
        <div
          id="connectWalletButton"
          class="button"
          @click="store.methods.updateConnectWalletModalVisible(true)"
        >
          Connect Wallet
        </div>
      </div>
    </div>
    <!-- BACKYARD AND CHILL -->
    <div
      v-show="props.type === 'backyard'"
      class="modal"
    >
      <img
        src="../assets/backyardimage.png"
        alt=""
        class="nftImage"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <div class="nftTitle">
          BACKYARD & CHILL
        </div>
        <div class="nftDescription">
          A picturesque, beautiful backyard to call your own.<br>
          This NFT Gallery is the ultimate chill without any of the upkeep. Oh, and don't forget to greet the wildlife.<br>
          <!-- <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }} / {{ store.state.totalSupply }}</span> -->
        </div>
        <div
          id="connectWalletButton"
          class="button"
          @click="store.methods.updateConnectWalletModalVisible(true)"
        >
          Connect Wallet
        </div>
      </div>
    </div>
    <!-- OBELISK -->
    <div
      v-show="props.type === 'obelisk'"
      class="modal"
    >
      <img
        src="../assets/obeliskimage.png"
        alt=""
        class="nftImage"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <div
          class="nftTitle"
        >
          OBELISK
        </div>
        <div
          class="nftDescription"
        >
          An ancient NFT Gallery, discovered after a misguided space tour crash landed here. To a World undiscovered by mankind... until now.
          But be warned, we are not alone at the Obelisk.<br>
          <!-- <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span> -->
        </div>
        <div
          id="connectWalletButton"
          class="button"
          @click="store.methods.updateConnectWalletModalVisible(true)"
        >
          Connect Wallet
        </div>
      </div>
    </div>
    <!-- HANAMI -->
    <div
      v-show="props.type === 'hanami'"
      class="modal"
    >
      <img
        src="../assets/hanamiimage.png"
        alt=""
        class="nftImage"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <div
          class="nftTitle"
        >
          HANAMI
        </div>
        <div
          class="nftDescription"
        >
          Miles beneath the earth lives a brilliant source of life in the form of a Cherry Blossom tree.
          Without the sun, this life form still thrives. And while you're down here, so will you and your NFTs.<br>
          <!-- <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span> -->
        </div>
        <div
          id="connectWalletButton"
          class="button"
          @click="store.methods.updateConnectWalletModalVisible(true)"
        >
          Connect Wallet
        </div>
      </div>
    </div>
    <!-- BAR BEYOND -->
    <div
      v-show="props.type === 'bar beyond'"
      class="modal"
    >
      <img
        src="../assets/barbeyondimage.png"
        alt=""
        style="object-fit: cover; height: 566px; width: 600px;"
      >
      <div
        id="rightPart"
        class="flexContainer"
      >
        <div
          class="nftTitle"
        >
          BAR BEYOND
        </div>
        <div
          class="nftDescription"
        >
          An exclusive destination where you not only control the VIP NFT list but also the vibe.
          This vibrant space, beaming with color and intricate mechanics exists years in the future…<br>
          <!-- <span
            style="font-family: 'Montserrat-Bold'"
          >Supply:  {{ store.state.supply }}  /  {{ store.state.totalSupply }}</span> -->
        </div>
        <div
          id="connectWalletButton"
          class="button"
          @click="store.methods.updateConnectWalletModalVisible(true)"
        >
          Connect Wallet
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.modal {
  height: 566px;
  width: 1200px;
  border-radius: 10px;
  display: flex;
}

#connectWalletButton {
  width: 500px;
}

#rightPart {
  height: 100%;
  width: 600px;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 0 10px 10px 0;
}

.nftDescription {
  height: 113px;
  width: 500px;
  color: #000;
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 22px;
}

</style>
