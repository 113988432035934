<script setup>
import { provide } from 'vue';
import store from './store/index';

provide('store', store);

</script>

<template>
  <router-view />
</template>

<style>

.nftImage {
  object-fit: cover;
  height: 566px;
  width: 600px;
  border-radius: 10px 0 0 10px;
}

.nftTitle {
  height: 46px;
  color: #603A96;
  font-family: 'Montserrat-Bold';
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
}

body {
  background-color: #0F0F0F;
  /* background-image: url('./assets/background.png'); */
  overflow-y: hidden;
  overflow-x: hidden;
}

.flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.button {
  height: 40px;
  border-radius: 5px;
  background-color: #7749B7;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
  line-height: 17px;
  text-align: center;
  display: flex;
  color: #FFF;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}
.button:hover {
  background-color: #804fc5;
}
.button:hover:active {
  background-color: #7749B7;
}

.whiteButton {
  height: 40px;
  border-radius: 5px;
  background-color: #FFF;
  color: #603A96;
  box-shadow: inset 0 0 0 1px rgb(96, 58, 150);
  margin-right: 10px;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
  line-height: 17px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}
.whiteButton:hover {
  background-color: rgba(96, 58, 150, 0.057);
}
.whiteButton:hover:active {
  background-color: #FFF;
}

@font-face {
  font-family: 'Montserrat-Light'; /*a name to be used later*/
  src: url('./assets/fonts/Montserrat-Light.ttf') format('truetype'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat'; /*a name to be used later*/
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face
{
  font-family: 'Montserrat-Bold'; /*a name to be used later*/
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype'); /*URL to font*/
}

img {
  -webkit-user-drag: none;
}

.modalBackground {
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: rgba(28,28,28,0.6);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalTitle {
  height: 28px;
  color: #603A96;
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.28px;
  line-height: 29px;
  text-align: center;
  margin-top: 22px;
}

.smallExitIcon {
  position: absolute;
  right: 19px;
  top: 14px;
  cursor: pointer;
}

</style>
