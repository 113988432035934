<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { inject, onMounted } from 'vue';
import InitialModal from '../components/InitialModal.vue';
import SmallModal from '../components/SmallModal.vue';
import MetaMaskConnectedModal from '../components/MetaMaskConnectedModal.vue';
import MintModal from '../components/MintModal.vue';
import ConnectWalletModal from '../components/ConnectWalletModal.vue';
/* eslint-disable vue/require-default-prop */

const store = inject('store');

// eslint-disable-next-line vue/require-prop-types
const props = defineProps(['nftType']);

onMounted(() => {
});

const goToHome = () => {
  window.location = 'https://sequin.world';
};

</script>

<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
  <div
    id="container"
    class="flexContainer"
  >
    <div @click="goToHome">
      <img
        src="../assets/brandmark.svg"
        alt=""
        style="position: absolute; top: 53px; left: 91px;cursor: pointer;"
      >
    </div>
    <InitialModal
      v-if="store.state.firstModalVisible"
      :type="props.nftType"
    />
    <ConnectWalletModal
      v-else-if="store.state.connectWalletModalVisible"
      :type="props.nftType"
    />
    <MintModal
      v-else-if="store.state.mintModalVisible"
      :type="props.nftType"
    />
    <div
      v-show="store.state.walletConnecting || store.state.showMintedModal"
      class="modalBackground"
    >
      <SmallModal
        :connectingWallet="store.state.walletConnecting"
        :showMintedModal="store.state.showMintedModal"
        :transactionUrl="store.transactionUrlCombined"
        :collectionUrl="store.state.mintCollectionUrl"
      />
    </div>
    <div
      v-show="store.state.walletConnected"
      class="modalBackground"
    >
      <MetaMaskConnectedModal />
    </div>
  </div>
</template>

<style scoped>

#container {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  margin-top: -9px;
  margin-left: -3px;
}

</style>
